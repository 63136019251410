.wrap-facts {
    box-sizing: border-box;
    width: 82%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.fact-box {
    box-sizing: border-box;
    position: relative;
    display: block;
    float: left;
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    border-right: 2px solid #5f5f5f;
}
.fact-box:last-child {
    border-right: 0;
}
.wrap-facts h3 {
    box-sizing: border-box;
    line-height: 1.2;
    margin: 0;
    font-family: 'KanitLight';
    font-size: 26px;
    letter-spacing: 2px;
    font-weight: 300;
    margin-bottom: 15px;
    color: #fff;
}
.fact-content img {
    max-width: 100%;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 20px;
}